import { Options } from '../options';

export const options: Options = {
    transportMethods: [
        { id: 'transport', value: 'ENVIO' },
        { id: 'pickup', value: 'RECOGER' },
    ],
    footerLinks: [
        { href: 'https://www.millesapori.pl/klauzula-informacyjna.html', translationKey: 'info-clause' },
        { href: 'https://www.millesapori.pl/polityka-prywatnosci.html', translationKey: 'privacy-policy' },
    ],
    logoSrc: {
        white: 'assets/svgs/mille-canary/logo-white.svg',
        dark: 'assets/svgs/mille-canary/logo-dark.svg',
    },
    cart: {
        productsProposal: {
            linkToProduct: false,
            productNoField: false,
            useRarelyPurchasedItems: false,
        },
    },
    products: {
        lowAvailability: {
            availabilityValue: 5,
            markValueAsDanger: false,
            cartItemTooltip: false,
            addToCartAlert: false,
            quantityInputAlert: false,
        },
    },
    documents: {
        typeFilters: false,
    },
    roles: {
        ROLE_ADMIN: ['documents-financial', 'documents-liabilities', 'documents-sales'],
        ROLE_SALESPERSON: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'customer.table.icon',
            'customer.table.no',
            'customer.table.vat-no',
            'customer.table.company-name',
            'customer.table.restaurant-name',
            'customer.table.blocked',
            'customer.table.display-as',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
        ],
        ROLE_USER: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
        ],
        ROLE_USER_LIMITED: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
        ],
    },
    standardFooter: false,
    footerAddresses: [
        {
            name: 'Tenerife',
            address: ['C/ Thomas Alva Edison, 4', 'Pol. Ind, La Campana', '38109 Santa Cruz de Tenerife', 'España'],
            additional: ['Tel: +34 922 626 093'],
            googleMaps: 'https://maps.app.goo.gl/ympuXW4RPwyHuqcr6',
        },
        {
            name: 'Gran Canaria',
            address: ['C/ Herraje Nave 38-40', 'Pol. Ind, Arinaga P3 Norte', '35119 Aguimes, Las Palmas', 'España'],
            additional: ['Tel: +34 928 790 896'],
            googleMaps: 'https://goo.gl/maps/68fy9F1nVtDPSpSh6',
        },
        {
            name: 'La Palma',
            address: ['Camino La Era 4', '38750 El Paso', 'Santa Cruz de La Palma', 'España'],
            additional: ['Tel: +34 922 485 666', 'Mov. 607 910 440'],
            googleMaps: 'https://goo.gl/maps/8aYs4nbALB4k2oBM8',
        },
        {
            name: 'Lanzarote',
            address: ['Pol. Ind. Montaña Roja', 'C/ Lituania 2 35570', 'Playa Blanca, Yaiza', 'España'],
            additional: ['Tel: +34 928 833 384'],
            googleMaps: 'https://goo.gl/maps/A9hkk7fp1oR9KMUq7',
        },
        {
            name: 'Fuerteventura',
            address: ['C/ Trillo, 7', 'Urb. Ind. El Matoral', '35600 Puerto del Rosario', 'España'],
            additional: ['Tel: +34 928 535 571'],
            googleMaps: 'https://goo.gl/maps/CUkH6Yi36NE5dwt6A',
        },
    ],
};
